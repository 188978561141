const castBoolean = (value) => {
  if (value === 'true') {
    return true
  } else if (value === 'false') {
    return false
  }
}
const nodeEnv = process.env.NODE_ENV
const apiEnv = process.env.REACT_APP_API_ENV ?? nodeEnv

const scopeSuffix = apiEnv === 'local' ? '-local' : apiEnv === 'development' ? '-dev' : ''

export default {
  sentry: {
    dsn:
      process.env.REACT_APP_SENTRY_DSN ??
      'https://79bf742f59974942b2eb7aca72364771@o358558.ingest.sentry.io/4504317976641536',
    enabled: castBoolean(process.env.REACT_APP_SENTRY_ENABLED) ?? false,
    environment: process.env.REACT_APP_SENTRY_ENV ?? nodeEnv,
  },
  msal: {
    auth: {
      clientId: process.env.REACT_APP_B2C_APP_ID,
      authority: process.env.REACT_APP_B2C_AUTHORITY,
      knownAuthorities: ['login.edoenergy.com'],
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  },
  api: {
    host: process.env.REACT_APP_API_URL ?? 'services-dev.apis.edoenergy.com',
    scopes: [
      'openid',
      `https://edoapps.onmicrosoft.com/services${scopeSuffix}/auth.read`,
      `https://edoapps.onmicrosoft.com/services${scopeSuffix}/auth.create`,
      `https://edoapps.onmicrosoft.com/services${scopeSuffix}/auth.update`,
      `https://edoapps.onmicrosoft.com/services${scopeSuffix}/auth.delete`,
      `https://edoapps.onmicrosoft.com/services${scopeSuffix}/point.read`,
      `https://edoapps.onmicrosoft.com/services${scopeSuffix}/building.update`,
    ],
    openadrScopes: [
      'openid',
      `https://edoapps.onmicrosoft.com/services${scopeSuffix}/openadr.read`,
      `https://edoapps.onmicrosoft.com/services${scopeSuffix}/openadr.create`,
      `https://edoapps.onmicrosoft.com/services${scopeSuffix}/openadr.update`,
    ],
    controlScopes: [
      'openid',
      `https://edoapps.onmicrosoft.com/services${scopeSuffix}/control.manage`,
      `https://edoapps.onmicrosoft.com/services${scopeSuffix}/timeseries.read`,
    ],
    reportScopes: ['openid', `https://edoapps.onmicrosoft.com/services${scopeSuffix}/report.read`],
  },
  flexApi: {
    host: 'flex-dev.apis.edoenergy.com',
    scopes: [
      'openid',
      `https://edoapps.onmicrosoft.com/flex${scopeSuffix}/user.read`,
      `https://edoapps.onmicrosoft.com/flex${scopeSuffix}/program.read.all`,
      `https://edoapps.onmicrosoft.com/flex${scopeSuffix}/building.read.all`,
    ],
  },
  torrensApi: {
    host: process.env.REACT_APP_TORRENS_API_URL ?? 'torrens-develop.apis.edoenergy.com/',
    scopes: ['openid', `https://edoapps.onmicrosoft.com/0f7496e6-1fb5-4251-ab17-7a1939f41fbe/User.Read`],
  },
}
