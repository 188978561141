import { apiClient } from '../API'

/**
 * Get auth users using serverside pagination filtering
 * @param {string} partnerId
 * @param {number} page
 * @param {number} resultsPerPage
 * @param {unknown} filter
 * @returns {Promise<{
 *  data: {
 *    username: string,
 *    firstName: string,
 *    lastName: string,
 *    phone: string,
 *  }[]
 * }>}
 */
export const getUsers = (partnerId, page, resultsPerPage, filter = {}) => {
  const params = {
    partner: [partnerId?.toLowerCase()],
    page: page,
    count: resultsPerPage,
    ...filter,
  }

  return apiClient.get(`/auth/user`, { params })
}

/**
 * Delete a user.
 */
export const deleteUser = (username) => {
  return apiClient.delete(`/auth/user/${username}`)
}

/**
 * Create a user.
 */
export const postUser = (payload) => {
  return apiClient.post(`/auth/user/`, payload)
}

/**
 * Update a user.
 */
export const patchUser = (username, payload) => {
  return apiClient.patch(`/auth/user/${username}`, payload)
}
