import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './UserManagementCard.scss'
import TextField from '@components/Form/TextField'

const UserManagementCard = (props) => {
  // destructure props
  const { pendingForm, icon, groupName, initial, action } = props

  return (
    <div class="user-card">
      <div className="info">
        <div className="icon-background">
          <FontAwesomeIcon class="icon" icon={icon} />
        </div>
        <span class="text">{groupName}</span>
      </div>
      <div className="form-container">
        <div className="form">
          <div className="row">
            <TextField
              name="username"
              label="Email"
              type="email"
              required
              initial={initial && initial?.username}
              value={pendingForm.username}
              onChange={props.onChange}
              disabled={action === 'edit' ? true : false}
            />
          </div>
          <div className="row">
            <TextField
              name="firstName"
              label="First Name"
              required
              initial={initial && initial?.firstName}
              value={pendingForm.firstName}
              onChange={props.onChange}
            />
            <TextField
              name="lastName"
              label="Last Name"
              required
              initial={initial && initial?.lastName}
              value={pendingForm.lastName}
              onChange={props.onChange}
            />
          </div>
          <div className="row">
            <TextField
              name="phoneNumber"
              label="Phone Number"
              type="tel"
              pattern="\+1[0-9]{10}"
              onInvalid={(e) => e.target.setCustomValidity('Please match the following format: +11234567890')}
              onInput={(e) => e.target.setCustomValidity('')}
              optionalLabel
              initial={initial && initial?.phoneNumber}
              value={pendingForm.phoneNumber}
              onChange={props.onChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserManagementCard
