import { Modal } from 'react-overlays'
import Button from '../Form/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Loader from '@components/Loader'
import './BaseModal.scss'

/**
 * Callback for when the modal goes into a hidden state.
 * @callback hideCallback
 */

/**
 * Callback to handle when the pending form displayed in the modal is submitted.
 * @callback submitCallback
 */

/**
 * Base modal layout that contains a header, body, and footer with configurable buttons.
 * @param {object} params
 * @param {string} params.title - Title of the modal used for the header
 * @param {JSX.Element} params.children - Children to render inside the content section
 * @param {hideCallback} params.onHide - Callback that fires when modal is hidden
 * @param {submitCallback} params.onSubmit - Callback that fires when save/ok button is clicked
 * @param {boolean} params.loading - Flag to indicate modal is in a loading state
 * @param {boolean} params.submitDisabled - Flag to disable submit button
 * @param {boolean} params.hideButtons - Flag to hide submit and cancel buttons
 * @param {boolean} params.showOkButton - Flag to show only an OK button instead of Cancel/Save
 * @param {object} params.style - Additional styles for the modal
 */
function BaseModal({
  title,
  children,
  onHide,
  onSubmit,
  loading = false,
  submitDisabled,
  hideButtons = false,
  showOkButton = false,
  style,
  ...restProps
}) {
  const stopPropagation = (e) => {
    e.stopPropagation()
  }
  return (
    <Modal
      className="modal"
      style={style}
      renderBackdrop={() => <div className="backdrop" onClick={stopPropagation}></div>}
      onHide={onHide}
      {...restProps}
    >
      <>
        {loading && <Loader isRelative={true} />}
        <div>
          <div className="header">
            {title}
            <FontAwesomeIcon onClick={onHide} icon={faTimes} style={{ float: 'right', cursor: 'pointer' }} />
          </div>
          <div className="content">{children}</div>
          {!hideButtons && (
            <div className="footer">
              {showOkButton ? (
                <Button label="OK" onClick={onSubmit} disabled={submitDisabled} />
              ) : (
                <>
                  <div className="button no-fill" onClick={onHide}>
                    Cancel
                  </div>
                  <Button label="Save" onClick={onSubmit} disabled={submitDisabled} />
                </>
              )}
            </div>
          )}
        </div>
      </>
    </Modal>
  )
}

export default BaseModal
