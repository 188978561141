import { useStoreState } from 'easy-peasy'
import { Routes, Route, Link } from 'react-router-dom'
import EventPage from './Event'
import EventSchedulerPage from './EventScheduler'

const ControlLayout = () => {
  const currentPartner = useStoreState((state) => state.partner.selected)

  return (
    <div className="App-module">
      <h3 style={{ textAlign: 'left' }}>
        {currentPartner?.label} / <Link to="..">Control</Link>
      </h3>
      <EventPage />
    </div>
  )
}

/**
 * Control routes.
 */
const Control = () => {
  return (
    <Routes>
      <Route index element={<ControlLayout />} />
      <Route path="scheduler" element={<EventSchedulerPage />} />
    </Routes>
  )
}

export default Control
