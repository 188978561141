/**
 * Text input field
 * @param {object} params
 * @param {string} params.label - Label of the text field
 * @param {boolean} params.required - Whether or not this field is required
 * @param {string|ReactNode} params.suffix - Suffix to be displayed inside the text field
 * @param {boolean} params.disabled - Whether the field is disabled
 */
function TextField({ label, required, initial, value, className, optionalLabel, suffix, disabled, ...restProps }) {
  const touched = value !== undefined && initial !== value

  return (
    <div className={'form-field' + (!value ? ' error' : '') + (disabled ? ' disabled' : '')}>
      {label && (
        <div className="title">
          <span>{label + (required ? '*' : '')}</span>
          {optionalLabel && <span className="optional">Optional</span>}
        </div>
      )}
      <div className="input-wrapper">
        <input
          className={`
            ${className || ''}
            ${touched ? 'touched' : ''}
            ${!touched && initial ? 'initial' : ''}
            ${suffix ? 'has-suffix' : ''}
            ${disabled ? 'disabled' : ''}
          `}
          value={value ?? ''}
          {...restProps}
          required={required}
          disabled={disabled}
        />
        {suffix && <span className="input-suffix">{suffix}</span>}
      </div>
    </div>
  )
}

export default TextField
