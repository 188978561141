import React, { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Stepper from '../../Components/Stepper'
import EventSchedulerSettings from './EventSchedulerSettings'
import EventSchedulerReview from './EventSchedulerReview'
import { createEventActionIntervals } from '../../API/Services/Control'
import { apiErrorHandler } from '../../Utilities/Error'
import Helpers from '@utilities/Helpers'

const EventScheduler = () => {
  const navigate = useNavigate()
  const steps = ['Settings', 'Review']
  const [currentStep, setCurrentStep] = useState(0)
  const [isSettingsValid, setIsSettingsValid] = useState(false)
  const [schedulerData, setSchedulerData] = useState(null)

  // handle changes in settings validity
  const handleSettingsValidityChange = useCallback((isValid, data) => {
    setIsSettingsValid(isValid)
    if (isValid) {
      setSchedulerData(data)
    }
  }, [])

  // Determine if the current step is valid
  const isCurrentStepValid = currentStep === 0 ? isSettingsValid : true

  // Handle step change
  const handleStepChange = (newStep) => {
    setCurrentStep(newStep)
  }

  // Handle cancellation
  const handleCancel = () => {
    navigate('/event')
  }

  const handleSubmit = async () => {
    const selectedDateTime = new Date(`${schedulerData.selectedDate}T${Helpers.parseTime(schedulerData.startTime)}:00`)

    // Handle promises
    const promises = schedulerData.selectedRows.map((item) =>
      createEventActionIntervals(item.id, selectedDateTime, parseInt(schedulerData.duration))
    )

    try {
      // Wait for all scheduling promises to resolve
      await Promise.all(promises)
    } catch (error) {
      // If error show toast
      apiErrorHandler({ response: { data: 'Event Scheduling Failed. Please review event details and try again.' } })
      return
    }

    navigate('/event')
  }

  const renderStepContent = () => {
    return (
      <>
        <div style={{ display: currentStep === 0 ? 'block' : 'none' }} className="settings-form-wrapper">
          <EventSchedulerSettings onValidityChange={handleSettingsValidityChange} />
        </div>
        <div style={{ display: currentStep === 1 ? 'block' : 'none' }}>
          {schedulerData && (
            <>
              <EventSchedulerReview schedulerData={schedulerData} />
            </>
          )}
        </div>
      </>
    )
  }
  return (
    <>
      <Stepper
        title={'Schedule Event'}
        steps={steps}
        currentStep={currentStep}
        isStepValid={isCurrentStepValid}
        onStepChange={handleStepChange}
        handleCancel={handleCancel}
        onSave={handleSubmit}
      />
      {renderStepContent()}
    </>
  )
}

export default EventScheduler
