import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FixedSizeList as List } from 'react-window'
import { useEffect, useMemo, useState } from 'react'

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faChevronDown} />
    </components.DropdownIndicator>
  )
}

const MenuList = ({ options, children, maxHeight, getValue }) => {
  const height = 35
  const [value] = getValue()
  const initialOffset = options.length > 0 ? options.indexOf(value) * height : 0

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
      style={{ zIndex: 1000 }}
    >
      {({ index, style }) => (
        <div title={options[index].label} style={{ ...style, overflow: 'hidden' }}>
          {children[index]}
        </div>
      )}
    </List>
  )
}

const customSelectStyles = {
  singleValue: (provided, state) => ({
    ...provided,
    color: state.selectProps.touched ? '#19D3C5' : '#383B41',
    ...(state.selectProps.touched && {
      fontStyle: 'italic',
      fontWeight: 'bold',
    }),
  }),
}

function DropdownField({
  label,
  required,
  isMulti,
  onChange,
  name,
  onCreate,
  labelOptions,
  labelId,
  onLabelUpdate,
  multiple,
  className,
  dropdownPrefix,
  initial,
  placeholder,
  isLoadingFromExternalSource,
  isSearchable = true,
  ...restProps
}) {
  const [isLoading, setIsLoading] = useState(false)
  const handleCreate = (inputValue) => {
    setIsLoading(true)
    onCreate(inputValue).then(() => setIsLoading(false))
  }

  const labelOptions_formatted = useMemo(() => {
    if (labelOptions) {
      return labelOptions.map((x) => ({
        value: x.id,
        label: x.name,
      }))
    } else {
      return []
    }
  }, [labelOptions])

  const touched = restProps.value !== undefined && JSON.stringify(initial) !== JSON.stringify(restProps.value)

  useEffect(() => {
    setIsLoading(isLoadingFromExternalSource)
  }, [isLoadingFromExternalSource])

  return (
    <div
      className={`${className ? className : 'form-field'}${restProps.disabled ? ' disabled' : ''}${
        !restProps.value ? ' error' : ''
      }`}
    >
      {(label || labelOptions) && (
        <div className="title">
          {(labelId || !labelOptions) && label + (required ? '*' : '')}
          {!labelId && labelOptions && (
            <Select
              onChange={onLabelUpdate}
              value={labelOptions_formatted.find((x) => x.value === labelId)}
              options={labelOptions_formatted}
              components={{ DropdownIndicator, MenuList }}
              className="dropdown-container"
              classNamePrefix="dropdown"
              placeholder="--Select Group Type--"
            />
          )}
        </div>
      )}
      {!onCreate && (
        <Select
          required={required}
          touched={touched}
          isMulti={isMulti}
          components={{ DropdownIndicator, MenuList }}
          className="dropdown-container"
          classNamePrefix={dropdownPrefix ? dropdownPrefix : 'dropdown'}
          placeholder={placeholder ?? (multiple ? '(Multiple)' : '--Select--')}
          onChange={(option) => onChange(option, name)}
          styles={customSelectStyles}
          isSearchable={isSearchable}
          {...restProps}
        />
      )}
      {onCreate && (
        <CreatableSelect
          required={required}
          isCreate
          touched={touched}
          components={{ DropdownIndicator, MenuList }}
          className={'dropdown-container' + (labelId ? '' : ' disabled')}
          classNamePrefix={dropdownPrefix ? dropdownPrefix : 'dropdown'}
          isDisabled={isLoading}
          isLoading={isLoading}
          onChange={(option) => onChange(option, name)}
          onCreateOption={handleCreate}
          placeholder={placeholder ?? (multiple ? '(Multiple)' : '--Select--')}
          styles={customSelectStyles}
          isSearchable={isSearchable}
          {...restProps}
        />
      )}
    </div>
  )
}

function DropdownFieldWrapper({ onTop = false, ...extraProps }) {
  return (
    <DropdownField
      {...extraProps}
      menuPortalTarget={onTop && document.body}
      styles={onTop && { menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  )
}

export default DropdownFieldWrapper
