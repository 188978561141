import { action, thunk } from 'easy-peasy'
import { getBuildings, getBuildingIds } from '../../API/Services/Control'

const Control = {
  buildingOptions: [],

  setBuildingOptions: action((state, payload) => {
    state.buildingOptions = payload
  }),

  getBuildings: thunk(async (actions, username) => {
    let buildingIds = [],
      buildingResults = []

    try {
      // Get building Ids
      const { data: ids } = await getBuildingIds(username)
      buildingIds = Object.keys(ids)

      // Get building data
      const { data } = await getBuildings(buildingIds)

      buildingResults = data
    } catch (e) {
      console.log(e)
    }

    actions.setBuildingOptions(buildingResults)
  }),
}

export default Control
