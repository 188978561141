import * as validator from 'validator'

import { flexClient } from '../FlexApi'

/**
 * Get users from Flex API using email filtering
 * @param {string[]} emails
 * @returns {Promise<{
 *  id: number,
 *  name: string,
 *  email: string,
 *  role: string,
 *  phone: string,
 *  notifications: {
 *    email: boolean,
 *    phone: boolean,
 *  },
 *  access: {
 *    siteIds: number[],
 *    buildingIds: number[],
 *    buildingCount: number
 *  }
 * }[]>}
 */
export const getUsers = async (emails) => {
  // Flex getUsers rejects malformed emails.
  // We need to filter out invalid emails before sending the get requests.
  const validEmails = emails.filter((email) => validator.isEmail(email))

  // batch requests to avoid url query string limitations
  const requests = []
  for (let i = 0; i < validEmails.length; i += 25) {
    const config = { params: { email: validEmails.slice(i, i + 25) } }
    requests.push(flexClient.get('/user', config))
  }

  const responses = await Promise.all(requests)

  return responses.reduce((agg, response) => {
    agg.push(...response.data)
    return agg
  }, [])
}
