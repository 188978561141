import { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import PageHeader from '@components/PageHeader'
import { addUserActions, editUserActions } from './userActions'
import UserManagementCard from '@components/UserManagementCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSpinner } from '@fortawesome/free-solid-svg-icons'
import '@src/App.scss'

const UserManagement = (props) => {
  const [formLoading, setFormLoading] = useState(true)
  const [pendingForm, setPendingForm] = useState({})
  const submitHTML = useRef(null)

  const navigate = useNavigate()

  // GLOBAL STATE
  const defaultUserData = useStoreState((state) => state.users.user)
  const currentPartner = useStoreState((state) => state.partner.selected)
  const creatingUser = useStoreState((state) => state.users.createUserLoading)
  const createUser = useStoreActions((actions) => actions.users.createUser)
  const updatingUser = useStoreState((state) => state.users.patchUserLoading)
  const patchUser = useStoreActions((actions) => actions.users.patchUser)
  const actionSuccessful = useStoreState((state) => state.users.actionSuccessful)
  const setActionSuccessful = useStoreActions((actions) => actions.users.setActionSuccessful)

  // destructure props
  const { pageName, action } = props

  // get the proper actions for the desired user management function
  let userActions = []
  switch (action) {
    case 'add':
      userActions = addUserActions(() => {
        submitHTML.current.click()
      })
      break
    case 'edit':
      userActions = editUserActions(() => {
        submitHTML.current.click()
      }, null) // handleDeleteUser not implemented yet
      break
  }

  /**
   * Handler for updating input field changes in the pending object.
   */
  const handleInputChange = (e) => {
    e.preventDefault()

    const target = e.target
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'number' && target.value !== ''
        ? Number(target.value)
        : target.value
    const name = target.name

    setPendingForm({
      ...pendingForm,
      [name]: value,
    })
  }

  /**
   * Form update handler for submitting of user data.
   */
  const handleUpdate = (e) => {
    e.preventDefault()

    const username = pendingForm.username

    let payload = {
      firstName: pendingForm.firstName,
      lastName: pendingForm.lastName,
      phoneNumber: pendingForm.phoneNumber,
    }

    if (action === 'add') {
      // new user
      ;(payload.partnerIds = [currentPartner.value]), // add partner id of logged-in user to payload
        createUser({ username, ...payload })
    } else if (action === 'edit') {
      // filter out the fields that didn't change
      Object.keys(payload).forEach((key) => payload[key] === defaultUserData[key] && delete payload[key])

      // update the existing user
      patchUser({ username, payload })
    }
  }

  useEffect(() => {
    setPendingForm(defaultUserData)
  }, [defaultUserData])

  // any form action
  useEffect(() => {
    let loading = creatingUser || updatingUser

    setFormLoading(loading)

    // if the action was successful, navigate back to the previous page
    if (actionSuccessful) {
      setActionSuccessful(false)

      // give time delay for toast success message to be seen then navigate back
      setTimeout(() => {
        navigate(-1)
      }, 1500)
    }
  }, [setFormLoading, creatingUser, updatingUser, actionSuccessful, setActionSuccessful])

  return (
    <div>
      <PageHeader pageName={pageName} userActions={userActions} />
      <div className="App-module" style={{ gap: '20px', alignItems: 'center' }}>
        <form onSubmit={handleUpdate}>
          {formLoading && <FontAwesomeIcon pulse icon={faSpinner} className="loading" />}
          <UserManagementCard
            icon={faUser}
            groupName="Personal Information"
            initial={defaultUserData}
            pendingForm={pendingForm}
            onChange={handleInputChange}
            action={action}
          />
          <input ref={submitHTML} type="submit" style={{ display: 'none' }} />
        </form>
      </div>
    </div>
  )
}

export default UserManagement
