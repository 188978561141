import React, { useMemo, useState, useEffect } from 'react'
import { useStoreState } from 'easy-peasy'
import Table from '@components/Table/Table'
import './EventSchedulerReview.scss'

const EventSchedulerSettings = ({ schedulerData }) => {
  const buildingOptions = useStoreState((state) => state.control.buildingOptions)
  const [buildingSelected, setBuildingSelected] = useState([])

  useEffect(() => {
    if (buildingOptions.length > 0) {
      const selectedIds = schedulerData.selectedRows.map((r) => r.id)
      const selectedData = buildingOptions.filter((r) => selectedIds.includes(r.id))
      setBuildingSelected(selectedData)
    }
  }, [schedulerData])

  const columns = useMemo(
    () => [
      {
        Header: 'Building',
        accessor: 'name',
        Cell: ({ value }) => <span style={{ color: '#3D2F90' }}>{value}</span>,
      },
      {
        Header: 'Site',
        accessor: 'siteName',
        Cell: ({ value }) => <span style={{ color: '#3D2F90' }}>{value}</span>,
      },
      {
        Header: '# of Equipment to Participate',
        accessor: 'equipmentCount',
        Cell: ({ value }) => <div style={{ textAlign: 'center', color: '#3D2F90' }}>{value}</div>,
        disableFilters: true,
      },
    ],
    []
  )

  return (
    <div>
      <table className="main-table review">
        <tbody>
          <tr>
            <td className="content-cell">
              <div className="review-title step-font" style={{ marginBottom: 20, color: '#3D2F90' }}>
                Settings
              </div>
              <div className="review-row">
                <div className="review-wrapper subtitle step-font">Program</div>
                <div className="review-wrapper info step-font">{schedulerData?.selectedProgram?.label}</div>
              </div>
              <div className="review-row justify-between" style={{ marginTop: 20 }}>
                <div className="review-row subwrapper">
                  <div className="review-wrapper subtitle step-font ">Event Date</div>
                  <div className="review-wrapper info step-font">{schedulerData.selectedDate}</div>
                </div>
                <div className="review-row subwrapper">
                  <div className="review-wrapper subtitle  step-font">Start Time</div>
                  <div className="review-wrapper info step-font">{schedulerData.startTime}</div>
                </div>

                <div className="review-row subwrapper">
                  <div className="review-wrapper subtitle  step-font">Time Zone</div>
                  <div className="review-wrapper info step-font">{schedulerData?.selectedTimezone}</div>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td className="content-cell review">
              <div className="location-title-wrapper">
                <div className="review-title step-font" style={{ marginBottom: 20, color: '#3D2F90' }}>
                  Location
                </div>
                <div className="review-row justify-between" style={{ marginTop: 20 }}>
                  <div className="review-row" style={{ width: '320px' }}>
                    <div className="review-wrapper subtitle  step-font"># of Buildings</div>
                    <div className="review-wrapper info step-font">{buildingSelected?.length ?? 0}</div>
                  </div>
                  <div className="review-row" style={{ width: '320px', visibility: 'hidden' }}>
                    <div className="review-wrapper subtitle  step-font"># of Equipment</div>
                    <div className="review-wrapper info step-font">7</div>
                  </div>
                </div>
              </div>

              <Table
                columns={columns}
                data={buildingSelected}
                initialPageSize={15}
                className={'building-table'}
                disableSelection={true}
                initialHiddenColumns={['equipmentCount']}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default EventSchedulerSettings
