import { tokenService } from '@utilities/Token'
import { scopeValidation } from '@utilities/ScopeValidation'
import axios from 'axios'
import appConfig from '../config'

// instance of an axios cancel token for generating request sources
const CancelToken = axios.CancelToken

// hold cancel sources for cancelled repeated requests against the same url
let cancels = []

// get api config
const flexConfig = appConfig.flexApi

// pull api host name from environment variable `FLEX_APP_API_URL`
const hostname = flexConfig.host

// axios api client instance to make requests with
const flexClient = axios.create({
  baseURL: `https://${hostname}`,
})

// add cancels for all requests
flexClient.interceptors.request.use(
  async (config) => {
    // use request url to key cancel tokens off of
    const url = config.url

    // if not cancel tokens for current url init and array
    if (!cancels[url]) cancels[url] = []

    // if cancels tokens for current url has length cancel the most recent request
    if (cancels[url].length) cancels[url][0].cancel()

    // only track at most 4 cancels tokens
    if (cancels[url].length > 4) cancels[url].pop()

    // add new cancel token to the beginning of the array for this url
    cancels[url].unshift(CancelToken.source())

    // add cancel token to the request
    config.cancelToken = cancels[url][0].token

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// add bearer token to all requests
flexClient.interceptors.request.use(
  async (config) => {
    // determine required scopes by url
    const scopes = flexConfig.scopes

    // get access token using Azure B2C
    const token = await tokenService.getAccessTokenSilently()(scopes)

    // validate the requested scopes are present
    scopeValidation(JSON.parse(JSON.stringify(scopes)), token.scopes)

    // set authorization header with the bearer token from Azure B2C
    config.headers['Authorization'] = `Bearer ${token.accessToken}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export { flexClient }
